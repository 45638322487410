import React from "react"
import { Link } from "gatsby"
import "./index2.scss"

import logo from "../../assets/landing/logo.svg"
import point from "../../assets/landing/point.svg"

import airplane from "../../assets/landing/hero-flight.svg"
import heroCloud1 from "../../assets/landing/hero-cloud1.svg"
import heroCloud2 from "../../assets/landing/hero-cloud2.svg"
import heroCloud3 from "../../assets/landing/hero-cloud3.svg"
import heroCloud4 from "../../assets/landing/hero-cloud4.svg"
import heroStar1 from "../../assets/landing/hero-star1.svg"
import heroStar2 from "../../assets/landing/hero-star2.svg"
import heroStar3 from "../../assets/landing/hero-star3.svg"
import heroCouple from "../../assets/landing/hero-couple.svg"
import heroSwift from "../../assets/landing/hero-swift.png"
import heroMobile from "../../assets/landing/hero-mobile.png"

import step1Clouds from "../../assets/landing/step1-clouds.svg"

import step2Plane from "../../assets/landing/step2-airplane.svg"
import step2Clouds from "../../assets/landing/step2-clouds.svg"
import step2Heart from "../../assets/landing/step2-heart.svg"
import step2Share from "../../assets/landing/step2-share.svg"
import step2Lady from "../../assets/landing/step2-lady.svg"
import step2Colors from "../../assets/landing/step2-colors.svg"

import step3Star from "../../assets/landing/step3-star.svg"
import step3Cloud from "../../assets/landing/step3-cloud.svg"
import step3Share from "../../assets/landing/step3-share.svg"
import step3Heart from "../../assets/landing/step3-heart.svg"
import step3Button from "../../assets/landing/step3-button.svg"
import step3Lines from "../../assets/landing/step3-lines.svg"
import step3Call from "../../assets/landing/step3-call.svg"
import step3Chat from "../../assets/landing/step3-chat.svg"

import satisfaction from "../../assets/landing/satisfaction.svg"
import easy from "../../assets/landing/easy.svg"
import experience from "../../assets/landing/experience.svg"
import demoLaptop from "../../assets/landing/demo-online-laptop.png"
import demoOnline from "../../assets/landing/demo-online.svg"
import demoClouds from "../../assets/landing/demo-clouds.svg"
import demoHearts from "../../assets/landing/demo-hearts.svg"

import testDrive from "../../assets/landing/test-drive.svg"
import tramite from "../../assets/landing/tramite.svg"
import entrega from "../../assets/landing/entrega.svg"

import { Image } from "../../components"

import { SEO } from "../../components"
import { urlWithSession } from "../../utils"

export default function Splash() {
  return (
    <>
      <SEO></SEO>
      <div className="landing-screen">
        <div className="hero">
          <div
            className="logo"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="350"
            data-aos-anchor-placement="center-bottom"
          >
            <img src={logo} alt=""></img>
          </div>
          <div className="info">
            <Link to={urlWithSession("/")}>
              <div className="right">
                <div className="image">
                  <div className="desktop">
                    <div className="airplane">
                      <img alt="airplane" src={airplane}></img>
                    </div>
                    <div className="cloud1">
                      <img alt="clouds" src={heroCloud1}></img>
                    </div>
                    <div className="cloud2">
                      <img alt="clouds" src={heroCloud2}></img>
                    </div>
                    <div className="cloud3">
                      <img alt="clouds" src={heroCloud3}></img>
                    </div>
                    <div className="cloud4">
                      <img alt="clouds" src={heroCloud4}></img>
                    </div>
                    <div className="star1">
                      <img alt="stars" src={heroStar1}></img>
                    </div>
                    <div className="star2">
                      <img alt="stars" src={heroStar2}></img>
                    </div>
                    <div className="star3">
                      <img alt="stars" src={heroStar3}></img>
                    </div>
                    <div className="couple">
                      <img alt="couple" src={heroCouple}></img>
                    </div>
                    <div className="car">
                      <img alt="suzuki-swift" src={heroSwift}></img>
                    </div>
                    <div className="phone">
                      <div className="screen-mobile">
                        <Image filename={"hero-mobile-screen.jpg"}></Image>
                      </div>
                      <img src={heroMobile} alt=""></img>
                    </div>
                    <Image filename={"hero-web.png"}></Image>
                  </div>
                </div>
              </div>
            </Link>
            <div className="left">
              <div
                className="title"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="350"
                data-aos-anchor-placement="center-bottom"
              >
                <h1>
                  La nueva forma <br></br>de comprar tu auto
                </h1>
              </div>
              <div className="items">
                <div
                  className="item"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="icon">
                    <img src={point} alt=""></img>
                  </div>
                  <div className="text">
                    <h3>Elige tu cuota mensual</h3>
                  </div>
                </div>
                <div
                  className="item"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="icon">
                    <img src={point} alt=""></img>
                  </div>
                  <div className="text">
                    <h3>Tasa tu auto actual en línea</h3>
                  </div>
                </div>
                <div
                  className="item"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="icon">
                    <img src={point} alt=""></img>
                  </div>
                  <div className="text">
                    <h3>Compara lo práctico y lo técnico</h3>
                  </div>
                </div>
                <div
                  className="item"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="icon">
                    <img src={point} alt=""></img>
                  </div>
                  <div
                    className="text"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="350"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <h3>Personaliza tu próximo auto y elige donde recibirlo</h3>
                  </div>
                </div>
              </div>
              <div
                className="call-to-action"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="350"
                data-aos-anchor-placement="center-bottom"
              >
                <Link to={urlWithSession("/")}>
                  <div className="button">Ver autos</div>
                </Link>
                <div
                  className="how-it-works"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    const elmnt = document.getElementById("how-it-works")
                    elmnt.scrollIntoView({ behavior: "smooth" })
                  }}
                  onKeyDown={e => {
                    const elmnt = document.getElementById("how-it-works")
                    e.key === "Enter" &&
                      elmnt.scrollIntoView({ behavior: "smooth" })
                  }}
                >
                  <h2>¿CÓMO FUNCIONA?</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="description-landing">
          <div className="items">
            <div
              className="item"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="icon">
                <img alt="experiencia" src={experience}></img>
              </div>
              <div className="title">
                <h4>Una nueva experiencia</h4>
              </div>
              <div className="text">
                Nuestros asesores están de tu lado, no ganan comisión
              </div>
            </div>
            <div
              className="item"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="icon">
                <img alt="easy" src={easy}></img>
              </div>
              <div className="title">
                <h4>Más fácil que nunca</h4>
              </div>
              <div className="text">
                Explora, crea y compra desde donde quieras
              </div>
            </div>
            <div
              className="item"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="icon">
                <img alt="satisfaccion" src={satisfaction}></img>
              </div>
              <div className="title">
                <h4>Satisfacción garantizada</h4>
              </div>
              <div className="text">
                Si no te gusta, tienes 7 días para devolver el auto
              </div>
            </div>
          </div>
          <div
            className="call-to-action"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="350"
            data-aos-anchor-placement="center-bottom"
          >
            <Link to={urlWithSession("/")}>
              <div className="button">Ver autos</div>
            </Link>
          </div>
        </div>
        <div className="how-it-works" id="how-it-works">
          <div
            className="title"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="350"
            data-aos-anchor-placement="center-bottom"
          >
            ¿CÓMO FUNCIONA?
          </div>
          <div
            className="subtitle"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="350"
            data-aos-anchor-placement="center-bottom"
          >
            Encuentra tu próximo auto de una manera fácil y desde donde quieras
          </div>
          <div className="step step1">
            <div
              className="principal-title"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="number">1</div>
              <div className="text">
                <h3>
                  Explora <span>y descubre</span>
                </h3>
              </div>
            </div>
            <div className="split">
              <div className="left step1-text-block">
                <div
                  className="text-block"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="title-block">Elige cómo quieres pagar</div>
                  <div className="text">
                    Define si quieres pagar al contado o en cuotas. Puedes
                    ajustar las cuotas al instante para encontrar la alternativa
                    que te quede mejor.
                  </div>
                </div>

                <div
                  className="text-block"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="title-block">¿Auto en parte de pago?</div>
                  <div className="text">
                    Puedes agregar tu actual auto en parte de pago y recibir una
                    oferta de tasación en sólo minutos.
                  </div>
                </div>
              </div>
              <div
                className="right"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="350"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="car">
                  <Image filename={"step1-car.png"}></Image>
                </div>
                <div className="clouds">
                  <img alt="clouds" src={step1Clouds}></img>
                </div>
                <Image filename={"landing-step1.png"}></Image>
              </div>
            </div>
            <div className="single final">
              <div
                className="text-block"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="350"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="title-block">¿No sabes cuál te gusta más?</div>
                <div className="text">
                  Compara entre los modelos que quieras de una manera simple y
                  clara. Revisa los datos prácticos y las fichas técnicas que
                  definimos especialmente para ti y que son claves a la hora de
                  decidir tu próximo auto.
                </div>
              </div>
            </div>
          </div>
          <div className="division gray">
            <div className="laptop">
              <div className="clouds">
                <img alt="clouds" src={demoClouds}></img>
              </div>
              <div className="hearts">
                <img alt="corazones" src={demoHearts}></img>
              </div>
              <div className="mobile">
                <Image filename="demo-online-mobile.png"></Image>
              </div>
              <div id="demoScroll" className={`screen-desktop`}>
                <Image filename="demo-desktop.png"></Image>
              </div>
              <img alt="laptop" src={demoLaptop}></img>
            </div>
            <div
              className="demo-online"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="icon">
                <img alt="pagina-dercocenter-x" src={demoOnline}></img>
              </div>
              <div className="info">
                <div className="title">Demo online</div>
                <div className="subtitle">
                  Nuestros asesores te darán un tour virtual de tu próximo auto
                  desde donde quieras
                </div>
                <div className="soon">PROXIMAMENTE</div>
              </div>
            </div>
          </div>
          <div className="step step2">
            <div
              className="principal-title"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="number">2</div>
              <div className="text">
                <h3>
                  Crea <span>tu nuevo auto</span>
                </h3>
              </div>
            </div>
            <div className="split">
              <div
                className="left"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="350"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="plane">
                  <img alt="plane" src={step2Plane}></img>
                </div>
                <div className="share">
                  <img alt="compartir" src={step2Share}></img>
                </div>
                <div className="heart">
                  <img alt="corazon" src={step2Heart}></img>
                </div>
                <div className="clouds">
                  <img alt="cloud" src={step2Clouds}></img>
                </div>
                <div className="colors">
                  <img alt="colores" src={step2Colors}></img>
                </div>
                <div className="lady">
                  <img alt="chica" src={step2Lady}></img>
                </div>
                <div className="car">
                  <Image filename="step2-car.png"></Image>
                </div>
                <div id="step2Scroll" className={`scroll-desktop`}>
                  <Image filename={"step2-desktop-scroll.png"}></Image>
                </div>
                <Image filename={"step2-desktop.png"}></Image>
              </div>
              <div className="right">
                <div
                  className="text-block"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="title-block">Arma tu nuevo auto </div>
                  <div className="text">
                    Configura tu próximo auto de una manera rápida y fácil.
                  </div>
                </div>
                <div
                  className="text-block white"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="title-block">Comparte y guarda</div>
                  <div className="text">
                    Copia el link o envía un email con los detalles de tu
                    próximo auto y comparte con quien quieras.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="step step3">
            <div
              className="principal-title"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="number">3</div>
              <div className="text">
                <h3>
                  Compra <span>tu auto soñado</span>
                </h3>
              </div>
            </div>
            <div className="split">
              <div
                className="left"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="350"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="star">
                  <img alt="star" src={step3Star}></img>
                </div>
                <div className="cloud">
                  <img alt="cloud" src={step3Cloud}></img>
                </div>
                <div className="heart">
                  <img alt="corazon" src={step3Heart}></img>
                </div>
                <div className="button-icon">
                  <img alt="boton" src={step3Button}></img>
                </div>
                <div className="lines">
                  <img alt="lineas" src={step3Lines}></img>
                </div>
                <div className="share">
                  <img alt="compartir" src={step3Share}></img>
                </div>
                <div className="car">
                  <Image filename={"step3-car.png"}></Image>
                </div>
                <div className="call">
                  <img alt="llamada" src={step3Call}></img>
                </div>
                <div className="chat">
                  <img alt="chat" src={step3Chat}></img>
                </div>
                <div className="mobile">
                  <Image filename={"step3-mobile.png"}></Image>
                </div>
              </div>
              <div className="right">
                <div
                  className="text-block"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="350"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="title-block">
                    <h3>Reserva tu auto</h3>
                  </div>
                  <div className="text">
                    Reserva online el auto que quieres y te contactará uno de
                    nuestros asesores para finalizar la compra.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="description-landing bottom">
          <div className="items">
            <div
              className="item"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="icon">
                <img alt="test drive" src={testDrive}></img>
              </div>
              <div className="title">
                <h4>Test Drive</h4>
              </div>
              <div className="text">
                Configura el auto que tú quieras y pruébalo sin compromiso.
              </div>
            </div>
            <div
              className="item"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="icon">
                <img alt="tramite" src={tramite}></img>
              </div>
              <div className="title">
                <h4>Trámites</h4>
              </div>
              <div className="text">
                Hacemos todos los trámites de la compra por ti.
              </div>
            </div>
            <div
              className="item"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="350"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="icon">
                <img alt="entrega" src={entrega}></img>
              </div>
              <div className="title">
                <h4>Entrega a domicilio</h4>
              </div>
              <div className="text">
                Te llevamos tu nuevo auto a la puerta de tu casa.
              </div>
            </div>
          </div>
          <div
            className="call-to-action"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="350"
            data-aos-anchor-placement="center-bottom"
          >
            <Link to={urlWithSession("/")}>
              <div className="button">Ver autos</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
